export const keyboard = {
  isEnterKey: (event: KeyboardEvent) =>
    event.code === 'Enter' || event.keyCode === 13,

  isSpaceKey: (event: KeyboardEvent) =>
    event.code === 'Space' || event.keyCode === 32,

  isEnterOrSpaceKey(event: KeyboardEvent) {
    return this.isEnterKey(event) || this.isSpaceKey(event);
  },
};
