import { createSignal, type Component } from 'solid-js';
import { OneTimeAction, api } from '~/modules/api';
import { CloseIcon } from '../../../components/icons';
import { Button, Checkbox, Popup } from '../../../components/ui';
import type {
  OneTimeActionExecutionContext,
  OneTimeActionRunner,
} from '../types';

const [showModal, setShowModal] = createSignal(false);

export const action = OneTimeAction.ANSWER_MARKETING_EMAIL_CONSENT;

export const run = async () => {
  setShowModal(true);
};

export const component: Component = (ctx: OneTimeActionExecutionContext) => {
  const [consent, updateConsentCheck] = createSignal(false);

  const closeModal = async () => setShowModal(false);

  const savePreference = async () => {
    try {
      setShowModal(false);

      await api.User.updateCommunicationPreferences({
        body: {
          marketingEmailConsent: consent(),
        },
      }).then(r => {
        if (r.error) {
          console.error('Error saving marketing email preference:', r.error);
          return;
        }
      });
    } catch (err) {
      console.error('Error saving marketing email preference:', err);
    }
  };
  return (
    <Popup
      show={showModal()}
      onClose={closeModal}
      position={{ sm: 'center', base: 'bottom' }}
    >
      <div class="relative sm:max-w-sm bg-white rounded-2xl p-6 py-9 space-y-6">
        <Button
          class="absolute size-6 top-4 right-4"
          variant="ghost"
          size="icon"
          onClick={closeModal}
        >
          <CloseIcon class="size-3" />
        </Button>

        <img
          class="w-64 mx-auto"
          src="/images/dreamflare-box.png"
          alt="DreamFlare Content Box"
        />

        <h3 class="df-font-beatrice font-bold text-2xl text-center">
          Welcome to DreamFlare
        </h3>

        <Checkbox
          id="consent-checkbox"
          label="Yes! Send me updates on the newest content and marketing messages via occasional
            DreamFlare Email"
          aria-label="Consent Check"
          value="consent"
          initialCheck={consent()}
          onChange={updateConsentCheck}
        />

        <Button
          class="mt-2"
          variant="primary"
          width="full"
          size="xl"
          onClick={savePreference}
        >
          Continue
        </Button>
      </div>
    </Popup>
  );
};

// NOTE: We normally do not use the default export, but we want to load runners dynamically
// so either we use the default export or we need to pick a name for an exported variable.
export default {
  action,
  component,
  run,
} satisfies OneTimeActionRunner;
