import {
  Show,
  createMemo,
  createSignal,
  splitProps,
  type ComponentProps,
} from 'solid-js';
import { cn, keyboard } from '~/utils';
import { CheckmarkIcon } from '~/components/icons';

interface CheckboxProps<T>
  extends Omit<ComponentProps<'input'>, 'onClick' | 'onChange' | 'value'> {
  id: string;
  label?: string;
  value: T;
  initialCheck?: boolean;
  onClick?: (value: T, event: MouseEvent | KeyboardEvent) => void;
  onChange?: (checked: boolean, event: MouseEvent | KeyboardEvent) => void;
  inputClass?: string;
  labelClass?: string;
}

export function Checkbox<T extends string>(props: CheckboxProps<T>) {
  const [, inputProps] = splitProps(props, [
    'children',
    'class',
    'onClick',
    'onChange',
  ]);

  const isControlled = props.checked !== undefined;

  const [isCheckedState, setIsCheckedState] = createSignal<boolean>(
    !!props.initialCheck,
  );
  const isChecked = createMemo(() =>
    isControlled ? props.checked : isCheckedState(),
  );

  const toggleIsChecked = (event: MouseEvent | KeyboardEvent) => {
    if (isControlled && props.onClick) {
      props.onClick(props.value, event);
      return;
    }

    setIsCheckedState(currentCheckedState => {
      if (props.onChange) {
        props.onChange(!currentCheckedState, event);
      }

      return !currentCheckedState;
    });
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (!keyboard.isEnterOrSpaceKey(event)) {
      return;
    }

    event.preventDefault();
    toggleIsChecked(event);
  };

  return (
    <div class={cn('flex items-start gap-2', props.class)}>
      <div class="relative size-6 shrink-0">
        <input
          {...inputProps}
          id={props.id}
          type="checkbox"
          class={cn(
            `peer size-full before:content-[''] appearance-none cursor-pointer
            rounded border-2 border-slate-300 focus:border-blue-400
            focus:outline-none checked:bg-blue-400 checked:border-blue-400
            checked:focus:border-blue-500 transition-all duration-75
            active:scale-95`,
            props.inputClass,
          )}
          onClick={toggleIsChecked}
          onKeyDown={handleKeyPress}
        />
        <CheckmarkIcon
          class="absolute pointer-events-none size-4 top-1 right-1
            -translate-1/2 text-white hidden peer-checked:block"
        />
      </div>

      <Show when={props.label}>
        <label
          class={cn('text-secondary text-xs cursor-pointer', props.labelClass)}
          for={props.id}
        >
          {props.label}
        </label>
      </Show>
    </div>
  );
}
